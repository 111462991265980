.task-information-wrapper {
    display: flex;
    flex-flow: wrap;
    width: 100%;

    h2 {
        font-weight: 700;
        font-size: 26px;
        color: $black;
        margin-bottom: 25px;

        @include large {
            font-size: 32px;
            margin-bottom: 32px;

        }
    }

    .heading {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 26px;

        h3 {
            font-weight: 700;
            font-size: 21px;
            letter-spacing: 0.03em;

            @include desktop2 {
                font-size: 22px;

            }

            @include large {
                font-size: 24px;

            }
        }

        .btn-seeAll {
            font-weight: 700;
            font-size: 14px;
            text-transform: uppercase;
            color: $tangerine;
            border: 0;

            @include desktop2 {
                font-size: 16px;

            }

            @include large {
                font-size: 18px;

            }

            &:hover {
                color: $tangerine;

            }
        }
    }

    .information-left-panel {
        @include mac {
            padding-right: 25px;
        }

        .task-info-content {
            width: 100%;
            display: flex;
            flex-flow: wrap;
        }

        .total-task {
            box-shadow: 0px 18px 22px -4px rgba(93, 105, 120, 0.08);
            border-radius: 8px;
            width: 200px;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;
            flex-flow: wrap;
            font-weight: 600;
            font-size: 60px;
            color: $black;

            @include small {
                width: 140px;
                padding: 20px 10px;

            }

            @include large {
                font-size: 82px;

            }
        }

        p {
            font-weight: 600;
            font-size: 16px;
            color: $black;
            width: 100%;
            text-align: center;
            padding: 0;
            text-transform: uppercase;

        }

        .sub-task-info {
            box-shadow: 0px 18px 22px -4px rgba(93, 105, 120, 0.08);
            border-radius: 8px;
            width: calc(100% - 230px);
            background-color: white;
            display: flex;
            align-items: center;
            margin-left: 30px;
            padding: 20px;

            @include small {
                width: calc(100% - 150px);
                margin-left: 10px;
                padding: 20px 10px;



            }

            .grid {
                width: calc(100% / 4);
                display: flex;
                flex-flow: wrap;

                .count-with-progress {
                    display: flex;
                    justify-content: center;
                    width: 100%;

                    span {
                        font-weight: 600;
                        font-size: 60px;
                        color: $black;

                        @include large {
                            font-size: 82px;

                        }
                    }

                    position: relative;
                }

                .barcontainer {
                    background-color: white;
                    position: relative;
                    // transform: translateY(-50%);
                    // top: 50%;
                    margin-right: 10px;
                    width: 20px;
                    // height: 75px;
                    float: left;
                    // margin-top: -14px;
                    border-radius: 6px;
                    overflow: hidden;

                    //border darken(#98AFC7, 40%) 3px solid
                    @include large {
                        width: 20px;
                        border-radius: 8px;
                    }
              
                }
                .vertical {
                    display: inline-block;
                    width: 20px;
                    height: 58px;
                    -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
                    transform: rotate(180deg);
                    @include large {
                        height: 75px;  
                    }
                  }
                  .vertical {
                    background-color: white;
                  }
                  .progress-bar {
                   
                  }
                  .progress-bar-success{
                    border-radius: 4px;
                    animation: grow 1.5s ease-out forwards;
                    transform-origin: top;

                  }

                .bar {
                    background: #B1B7C0;
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 80%;
                    //border-top: 6px solid #FFF;
                    box-sizing: border-box;
                    border-radius: 6px;
                    animation: grow 1.5s ease-out forwards;
                    transform-origin: top;

                    

                    @include large {
                        border-radius: 8px;

                    }

                }

                @keyframes grow {
                    from {
                        transform: scaleY(0);
                    }
                }


                p {
                    font-weight: 600;
                    font-size: 14px;
                    color: $black;
                    width: 100%;
                    text-align: center;
                    padding: 0;
                    text-transform: uppercase;

                    @include large {
                        font-size: 16px;

                    }

                }
            }

        }


        .team-allocation-wrapper {
            box-shadow: 0px 18px 22px -4px rgba(93, 105, 120, 0.08);
            border-radius: 8px;
            width: 100%;
            background-color: white;
            margin-top: 32px;
            padding: 20px;
            @include mac {
                min-height: 570px;
              }
            @include large {
                padding: 30px;

            }


            .allocation-content-wrapper {
                display: flex;
                flex-flow: wrap;
                width: calc(100% + 20px);
                margin: 0 -10px;

                .allocation-list-grid {
                    background: #F6F8FB;
                    border-radius: 12px;
                    margin-bottom: 15px;
                    width: calc(100% / 2 - 10px);
                    padding: 20px 12px;
                    display: flex;
                    flex-flow: wrap;
                    // transition: all ease-in-out .3s;
                    margin-bottom: 20px;
                    margin-right: 5px;
                    margin-left: 5px;
                    position: static;



                    @include mac {
                        width: calc(100% / 2 - 10px);
                        
                    }

                    @include desktop {
                        margin-bottom: 15px;
                        width: calc(100% / 3 - 10px);

                    }

                    @include desktop2 {
                        margin-bottom: 15px;
                        width: calc(100% / 3 - 10px);
                    }

                    @include large {
                        margin-bottom: 20px;
                        border-radius: 16px;
                        width: calc(100% / 3 - 10px);
                    }

                    .member-profile {
                        width: 100%;
                        display: flex;
                        flex-flow: wrap;
                        min-height: 43px;

                        .name {
                            margin: 0;
                            width: calc(100% - 40px);
                            margin-right: 15px;
                            font-weight: 600;
                            font-size: 14px;
                            color: $black;
                            word-break: break-word;

                            @include desktop {
                                font-size: 14px;

                            }

                            @include large {
                                font-size: 18px;

                            }
                        }

                        .tag-info {
                            width: 25px;
                            height: 29px;
                            border-radius: 8px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: white;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 21px;
                            text-transform: uppercase;
                            background-color: $blue;
                        }
                    }

                    .assign-status {
                        width: 100%;
                        margin: 16px 0 20px 0;
                        display: flex;
                        flex-flow: wrap;


                        .not-assign-tag {
                            background: #B1B7C0;
                            border-radius: 6px;
                            padding: 3px 8px;
                            text-transform: uppercase;
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 21px;
                            color: white;
                            display: flex;
                            align-items: center;
                            height: 32px;

                            @include large {
                                font-size: 14px;
                                border-radius: 8px;


                            }
                        }

                        .assigned-tag {
                            width: fit-content;
                            padding: 3px 10px;
                            font-size: 13px;
                            letter-spacing: 0px;
                            font-weight: 600;
                            text-transform: uppercase;
                            background: #EFBF62;
                            border-radius: 6px;
                            color: white;
                            display: flex;
                            align-items: center;
                            height: 32px;

                            @include mac {
                                padding: 3px 10px;
                                font-size: 12px;

                            }

                            @include large {
                                font-size: 14px;
                                padding: 4px 10px;
                                border-radius: 8px;


                            }

                            img {
                                width: 100%;
                                background-size: cover;
                                height: 100%;
                                border-radius: 50%;
                                background-color: white;
                            }
                        }

                        .done-tag {
                            width: fit-content;
                            padding: 3px 10px;
                            font-size: 13px;
                            letter-spacing: 0px;
                            font-weight: 600;
                            text-transform: uppercase;
                            background: #71C49C;
                            border-radius: 6px;
                            color: white;
                            display: flex;
                            align-items: center;
                            height: 32px;

                            @include mac {
                                padding: 3px 10px;
                                font-size: 12px;

                            }

                            @include large {
                                font-size: 14px;
                                padding: 4px 20px;
                                border-radius: 8px;


                            }

                            img {
                                width: 100%;
                                background-size: cover;
                                height: 100%;
                                border-radius: 50%;
                                background-color: white;
                            }
                        }

                        .suggestion-tag {
                            width: fit-content;
                            padding: 3px 10px;
                            font-size: 12px;
                            letter-spacing: 0px;
                            font-weight: 600;
                            text-transform: uppercase;
                            background: #40BBBD;
                            color: white;
                            display: flex;
                            align-items: center;
                            border-top-left-radius: 6px;
                            border-bottom-left-radius: 6px;
                            height: 32px;

                            @include mac {
                                padding: 3px 10px;
                                font-size: 11px;

                            }

                            @include desktop {
                                padding: 3px 10px;
                                font-size: 11px;
                            }

                            @include desktop2 {
                                padding: 3px 10px;

                            }

                            @include large {
                                padding: 4px 10px;
                                border-top-left-radius: 8px;
                                border-bottom-left-radius: 8px;


                            }

                            img {
                                width: 100%;
                                background-size: cover;
                                height: 100%;
                                border-radius: 50%;
                                background-color: white;

                            }
                        }

                        .accept-tag {
                            width: fit-content;
                            padding: 4px 10px;
                            font-size: 11px;
                            letter-spacing: 0px;
                            font-weight: 600;
                            cursor: pointer;
                            text-transform: uppercase;
                            background: $blue;
                            color: white;
                            display: flex;
                            align-items: center;
                            border-top-right-radius: 6px;
                            border-bottom-right-radius: 6px;
                            height: 32px;

                            @include mac {
                                padding: 3px 10px;
                                font-size: 11px;

                            }

                            @include desktop {
                                padding: 3px 10px;
                                font-size: 11px;
                            }

                            @include desktop2 {
                                padding: 3px 10px;

                            }

                            @include large {
                                padding: 3px 10px;
                                border-top-right-radius: 8px;
                                border-bottom-right-radius: 8px;


                            }
                            .accept-icon {
                                width: 24px;
                            }
                        }
                    }

                    .status-with-action {
                        display: flex;
                        flex-flow: wrap;
                        width: 100%;
                        align-items: center;
                        position: relative;
                        z-index: 91;

                        .status {
                            width: 60%;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 24px;
                            text-transform: uppercase;
                            color: $blue;

                            @include mac {
                                font-size: 14px;

                            }

                            @include large {
                                font-size: 16px;

                            }
                        }

                        .completed-tag-content {
                            width: 60%;

                        }

                        .completed-tag {
                            width: fit-content;
                            padding: 4px 10px;
                            font-size: 12px;
                            letter-spacing: 0px;
                            text-transform: uppercase;

                            @include mac {
                                padding: 6px 10px;
                                font-size: 13px;

                            }

                            @include large {
                                font-size: 14px;
                                padding: 10px 20px;

                            }

                            img {
                                max-width: 20px;
                                margin-right: 3px;
                            }
                        }

                        .action-btn {
                            width: 40%;
                            display: flex;
                            justify-content: flex-end;
                            position: relative;
                            z-index: 91;
                            .delete {
                                cursor: pointer;
                                position: relative;
                                right: -5px;
                                height: 25px;
                                position: relative;
                                z-index: 91;
                                ._delete-icon-hover {
                                    display: none;
                                  }
                                  ._delete-icon {
                                    display: inline-block;
                                  }


                            }

                            .edit {
                                cursor: pointer;
                                position: relative;
                                top: 3px;
                                margin-right: 7px;
                                height: 25px;
                                position: relative;
                                z-index: 91;
                            }
                        }

                    }

                    &:hover {
                        .status-with-action {
                            position: relative;
                            z-index: 91;
                            .status {
                                color: white;
                            }
                        }

                        .assign-status {
                            position: relative;
                            z-index: 91;
                            .assign-tag {
                                background-color: #B1B7C0;
                            }
                        }
                    }

                    &:hover {
                        background: linear-gradient(120.3deg, #F7A072 43.91%, #FFFFFF 126.57%);

                        .member-profile {
                            .name {
                                color: $white;
                            }
                        }

                        .email {
                            a {
                                color: $white;
                            }

                            color:$white;
                        }

                        path {
                            fill: white !IMPORTANT;
                        }
                        .delete {
                            position: relative;
                            z-index: 99;
                            ._delete-icon {
                              display: none !important;
                            }
                            ._delete-icon-hover {
                              display: inline-block !important;
                            }
                          }

                    }

                }
            }
        }


    }

    .information-right-panel {
        margin-top: 30px;

        @include mac {
            margin-top: 0;
        }

        .team-member-wrapper {
            box-shadow: 0px 18px 22px -4px rgba(93, 105, 120, 0.08);
            border-radius: 8px;
            width: 100%;
            background-color: white;
            margin-top: 32px;
            padding: 25px 25px;

            @include large {
                padding: 30px;

            }

            .team-member-content-wrapper {
                display: flex;
                width: calc(100% + 20px);
                margin: 0 -10px;
                overflow-x: auto;


                .team-member-list-grid {
                    background: #F6F8FB;
                    border-radius: 12px;
                    width: calc(100% / 2 - 16px);
                    padding: 16px 10px;
                    display: flex;
                    flex-flow: wrap;
                    transition: all ease-in-out .3s;
                    margin-right: 8px;
                    margin-left: 8px;
                    justify-content: center;
                    margin-bottom: 10px;
                    min-width: 182px;
                    @include mac {
                      width: calc(100% / 2 - 16px);
                    }
                    @include desktop2 {
                        padding: 16px 10px;
                        width: calc(100% / 2 - 18px);
                    }

                    ._profile-img {
                        width: 82px;
                        height: 82px;
                        position: relative;
                        background-color: white;
                        border-radius: 50%;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                            border-radius: 50%;
                        }

                        .badges {
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            width: auto;
                            height: auto;
                        }
                    }

                    .name {
                        font-weight: 600;
                        font-size: 16px;
                        color: $black;
                        width: 100%;
                        text-align: center;
                        margin: 8px 0;
                    }

                    .designation {
                        font-weight: 400;
                        font-size: 12px;
                        color: $black;
                        width: 100%;
                        text-align: center;
                        padding-bottom: 6px;

                    }

                    .free-slot {
                        display: flex;
                        flex-flow: wrap;
                        width: 100%;
                        border-top: 1px solid #373E45;
                        text-align: center;
                        justify-content: center;
                        .task-capacity-n{
                            justify-content: center;
                        }

                        .text {
                            width: 100%;
                            font-weight: 600;
                            font-size: 12px;
                            color: $black;
                            padding-bottom: 12px;
                            padding-top: 10px;
                        }

                        .sizes {
                            display: flex;
                            align-items: center;
                            font-weight: 600;
                            font-size: 14px;
                            color: $black;
                            margin-right: 4px;

                            @include desktop {
                                margin-right: 9px;

                            }

                            @include desktop2 {
                                margin-right: 8px;

                            }

                            &:last-child {
                                margin-right: 0;
                            }

                            .tag-info {
                                width: 22px;
                                height: 25px;
                                border-radius: 8px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: white;
                                font-weight: 600;
                                font-size: 13px;
                                line-height: 21px;
                                text-transform: uppercase;
                                background-color: $blue;
                                margin-right: 3px;

                                @include desktop2 {
                                    margin-right: 5px;
                                    width: 23px;
                                    height: 25px;
                                    font-size: 13px;


                                }
                            }
                        }
                    }

                }
            }
        }

        .team-skills-wrapper {
            box-shadow: 0px 18px 22px -4px rgba(93, 105, 120, 0.08);
            border-radius: 8px;
            width: 100%;
            background-color: white;
            margin-top: 32px;
            padding: 30px;
            &.member {
                padding-right: 10px;
                padding-left: 10px;
            }

            ul {
                margin: 0;
                padding: 0;

                li {
                    display: inline-flex;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 15px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    p {
                        width: 170px;

                        @include large {
                            width: 240px;

                        }
                    }

                    .progress-bg-text {
                        width: calc(100% - 170px);
                        padding-left: 10px;

                        @include large {
                            width: calc(100% - 240px);


                        }

                        .progress {
                            background-color: $grey;
                            height: 24px;
                            border-radius: 20px;


                            .progress-bar {
                                background-color: $tangerine;
                                border-radius: 10px;
                                font-size: 14px;
                                min-width: 35px;

                            }
                        }
                    }
                }
            }
        }
    }



}