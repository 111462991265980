p {
  line-height: normal;
  font-weight: 400;
  font-size: 14px;
  color: $black;

  @include mac {
    font-size: 15px;
  }

  @include desktop2 {
    font-size: 16px;
  }

  @include large {
    font-size: 21px;
  }
}

.h-100vh {
  height: 100vh;
}

.main-container {
  width: calc(100% - 180px);
  min-height: calc(100vh - 105px);
  margin: 50px;
  position: relative;
  left: 60px;
  background: #ffffff;
  box-shadow: 0px 18px 22px -4px rgba(93, 105, 120, 0.08);
  border-radius: 8px;
  padding: 30px;
  transition: all ease-in-out 0.3s;

  @include desktop {
    width: calc(100% - 160px);
    min-height: calc(100vh - 112px);
    margin: 50px;
    left: 60px;
  }

  @include large {
    width: calc(100% - 280px);
    min-height: calc(100vh - 160px);
    margin: 90px 100px 70px 100px;
    left: 80px;
    padding: 70px;
  }
  &.shrink-width {
    transition: all ease-in-out 0.3s;
    width: calc(100% - 230px);
    left: 165px;
    @include desktop {
      width: calc(100% - 230px);
      left: 165px;
    }

    @include large {
      width: calc(100% - 324px);
      left: 175px;
    }
  }
  &.spaceTop {
    margin-top: 20px;
  }

  &.transparent-bg {
    background-color: transparent;
    box-shadow: none;
   
  }

  &.main-container-2 {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;

    .common-right-panel {
      background: #ffffff;
      box-shadow: 0px 18px 22px -4px rgba(93, 105, 120, 0.08);
      border-radius: 8px;
      padding: 20px 15px;
      width: calc(100%);
      justify-content: flex-start;

      @include mac {
        width: calc(38%);
        min-height: 100%;
      }

      @include large {
        padding: 40px 30px;
      }
    }

    .common-left-panel {
      background: #ffffff;
      box-shadow: 0px 18px 22px -4px rgba(93, 105, 120, 0.08);
      border-radius: 8px;
      padding: 20px;
      width: calc(100%);
      justify-content: flex-start;

      @include mac {
        width: calc(62% - 20px);
        margin-right: 20px;
        min-height: 100%;
      }

      @include large {
        padding: 40px;
      }
    }

    .add-btn.absolute {
      right: 30px;

      @include large {
        right: 70px;
      }
    }
  }
}

.notification-bar {
  width: calc(100% - 180px);
  margin: 30px 50px 0 50px;
  position: relative;
  left: 60px;
  background: #ffffff;
  box-shadow: 0px 18px 22px -4px rgba(93, 105, 120, 0.08);
  border-radius: 8px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include desktop {
    width: calc(100% - 160px);
    margin: 30px 50px 0 50px;
    left: 60px;
  }

  @include large {
    width: calc(100% - 280px);
    margin: 40px 100px 0px 100px;
    left: 80px;
    padding: 0 70px;
  }
  &.shrink-width {
    transition: all ease-in-out 0.3s;
    width: calc(100% - 230px);
    left: 165px;
    @include desktop {
      width: calc(100% - 230px);
      left: 165px;
    }

    @include large {
      width: calc(100% - 324px);
      left: 175px;
    }
  }
  .notification {
    cursor: pointer;
    width: 48px;
    height: 48px;
    background: #f6f8fb;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    img {
      max-width: 22px;

      @include large {
        max-width: none;
      }
    }
    .m-alert {
      background-color: $tangerine;
      width: 8px;
      height: 8px;
      border-radius: 8px;
      position: absolute;
      right: 8px;
      top: 8px;
    }
  }
  .notification-drop-down {
    position: absolute;
    background: #ffffff;
    box-shadow: 0px 18px 22px -4px rgba(93, 105, 120, 0.08);
    border-radius: 16px;
    right: 0;
    top: 80px;
    width: 450px;
    padding: 30px;
    z-index: 99;
    .nlist-main {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .notification-scrollbar {
      max-height: 265px;
      overflow: auto;
      padding-right: 5px;
    }
    h3 {
      font-weight: 600;
      font-size: 24px;
      color: $black;
      margin: 0 0 20px 0;
    }
    .notification-by-day {
      width: 100%;
      display: flex;
      flex-flow: wrap;
      padding-bottom: 10px;

      h4 {
        font-weight: 600;
        font-size: 16px;
        color: #b1b7c0;
        width: 100%;
        margin: 0 0 15px 0;
      }
      .masseges {
        background: #f6f8fb;
        border-radius: 16px;
        width: 100%;
        padding: 15px;
        display: flex;
        // align-items: center;
        flex-flow: wrap;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
        &.grey {
          background: #f6f8fb;
        }
        &.yellowlight {
          background: #fdece3;
          .btn-accepted {
            .btn-accepted {
              opacity: 0;
            }
            .btn-accept {
              opacity: 1;
            }
          }
        }
        &:hover {
          transition: all ease-in-out 0.3s;
        }

        .left-content {
          width: calc(100% - 100px);
          padding-right: 15px;
          &.leftfull-width {
            width: 100%;
          }
          p {
            width: 100%;
          }
          .sizes-info-notification {
            width: 100%;
            display: flex;
            flex-flow: wrap;
            padding-top: 10px;
            .sizes-n {
              width: 29px;
              height: 29px;
              background: #373e45;
              border-radius: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: white;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              text-transform: uppercase;
            }
            .text {
              width: calc(100% - 29px);
              font-weight: 600;
              font-size: 16px;
              line-height: 120%;
              padding-left: 0px;
              color: #373e45;
            }
          }
        }
        .btn-accepted {
          width: 100px;
          position: relative;
          height: 100%;
          .btn-accepted {
            background: #b1b7c0;
            border-radius: 8px;
            display: flex;
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            font-size: 15px;
            line-height: 24px;
            color: #ffffff;
            text-transform: uppercase;
            position: absolute;
            transition: all ease-in-out 0.3s;
            top: 8px;
            @include large {
              font-size: 16px;
            }
          }
          .btn-accept {
            background: $tangerine;
            border-radius: 8px;
            display: flex;
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            font-size: 15px;
            line-height: 24px;
            color: #ffffff;
            text-transform: uppercase;
            position: absolute;
            opacity: 0;
            transition: all ease-in-out 0.3s;
            top: 8px;
            @include large {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

.heading-common {
  width: 100%;
  position: relative;
  text-align: center;
  &._heightadd{
    height: 70px;
  }

  sup {
    top: -15px;
    left: 7px;
    cursor: pointer;
  }

  h1 {
    letter-spacing: 0.03em;
    font-weight: 700;
    font-size: 28px;
    color: $black;
    // margin: 0 0 8px 0;
    max-width: 60%;
    margin: 0 auto;

    @include mac {
      font-size: 30px;
    }

    @include desktop {
      font-size: 34px;
    }

    @include large {
      font-size: 48px;
    }

    span {
      color: $tangerine;
    }
  }

  p {
    letter-spacing: 0.03em;
    font-weight: 400;
    font-size: 14px;
    color: $black;

    @include mac {
      font-size: 16px;
    }

    @include large {
      font-size: 24px;
    }
  }

  .back-icon {
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    max-width: 28px;

    @include mac {
      max-width: 33px;
    }

    @include large {
      max-width: 40px;
    }
  }
}

.grey-bg {
  background-color: $grey;
}

.logout-icon {
  max-width: 40px;
  fill: #f7a072;
}
.logout {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 60px;
  right: 0;
}

.scrollbar::-webkit-scrollbar {
  background: rgba(77, 116, 164, 0.1);
  width: 7px;
  border-radius: 12px;
}

.scrollbar::-webkit-scrollbar-track {
  background: rgba(77, 116, 164, 0.1);
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(77, 96, 164, 0.5);
  border-radius: 12px;
  border: 0px solid #f4f4f5;
  opacity: 0.1;
}

.scrollbar::-webkit-scrollbar-button {
  display: none;
}

._back {
  position: absolute;
  left: 30px;
  top: 30px;
  cursor: pointer;
  max-width: 28px;

  @include mac {
    max-width: 33px;
    left: 50px;
  }

  @include large {
    max-width: 40px;
    left: 115px;
    top: 15px;
  }
}

.completed-tag {
  background-color: $green;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 6px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  color: white;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.4px;

  @include mac {
    padding: 10px 20px;
    font-size: 14px;
  }

  @include large {
    font-size: 18px;
    padding: 16px 32px;
  }

  img {
    margin-right: 6px;
  }
}

.top-right-btn {
  position: absolute;
  right: -10px;
  display: flex;
  top: 10px;

  @include mac {
    top: 12px;
    right: 0;
  }

  .btn-top {
    background-color: white;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    border: solid 1px $tangerine;
    cursor: pointer;

    @include mac {
      height: 40px;
      width: 40px;
      border-radius: 8px;
    }

    img {
      max-width: 18px;

      @include mac {
        max-width: none;
      }
    }

    &.edit-btn {
      margin-right: 5px;

      @include mac {
        margin-right: 10px;
      }
    }

    &.delete-btn {
    }
  }
}

.spinner-wrap {
  width: 100%;
  // position: absolute;
  justify-content: center;
  display: flex;
  align-items: center;
  z-index: 4;
  top: 0;
  
}
.no-data-found {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  z-index: 4;
  height: 48vh;
  p {
    color: $tangerine;
    border: solid 1px #ccc;
    border-radius: 6px;
    padding: 10px 50px;
  }
}

.nlist-main {
  margin-bottom: 12px;
}

.dashboard-no-data {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  z-index: 4;
  height: 20vh;
  p {
    color: $tangerine !important;
    border: solid 1px #ccc;
    border-radius: 6px;
    padding: 10px 50px !important;
    width: fit-content !important;
  }
}

.task-capacity-n {
  width: 100%;
  display: flex;
  flex-flow: wrap;
  &.smll-grid {
    .tsk-capacity-c-bt {
      padding: 0 7px;
      font-size: 13px;
      margin-right: 5px;
    }
  }
  .tsk-capacity-c-bt {
    border-radius: 6px;
    padding: 0 10px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 14px;
    margin-right: 7px;
    font-weight: 600;
    margin-bottom: 5px;

    &:last-child {
      margin-right: 0;
    }

    &._capacity-n {
      background-color: #edf1f4;
      color: $black;
    }
    &._small-n {
      background-color: #a2b6ff;
    }
    &._medium-n {
      background-color: #6982df;
    }
    &._large-n {
      background-color: #4d60a4;
    }
  }
}

.skill-dimension-drop-down {
  position: absolute;
  background: #ffffff;
  border: 1px solid $tangerine;
  box-shadow: 0px 18px 22px -4px rgba(93, 105, 120, 0.08);
  border-radius: 16px;
  right: 0;
  // top: 80px;
  width: 450px;
  padding: 10px;
  z-index: 99;
  opacity: 0;
  z-index: 99;
  ul {
    margin: 0;
    padding: 0;

    li {
        display: inline-flex;
        align-items: center;
        width: 100%;
        margin-bottom: 5px;

        &:last-child {
            margin-bottom: 0;
        }

        p {
            width: 170px;

            @include large {
                width: 240px;

            }
        }

        .progress-bg-text {
            width: calc(100% - 170px);
            padding-left: 5px;

            @include large {
                width: calc(100% - 240px);


            }

            .progress {
                background-color: $grey;
                height: 15px;
                border-radius: 20px;


                .progress-bar {
                    background-color: $tangerine;
                    border-radius: 10px;
                    font-size: 14px;
                    min-width: 35px;

                }
            }
        }
    }
}
}
